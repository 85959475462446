<template>
    <nav
        role="navigation"
        class="relative w-full justify-center px-24"
    >
        <ul class="flex justify-center">
            <li
                v-for="treeItem in treeItems"
                :key="treeItem.key"
                :ref="el => handleItemRef(treeItem, el)"
                class="UnderlinedNav -mb-px flex items-center gap-1 border-b px-4 py-4 text-sm font-medium uppercase tracking-wider hover:text-gray-700"
                :class="[
                    treeItem.type === 'StorefrontCatalog' &&
                    displayedNode === treeItem.key
                        ? 'z-20 !border-white'
                        : ''
                ]"
                @mouseover="handleMouseOver(treeItem)"
                @mouseleave="handleMouseLeave(treeItem)"
            >
                <nuxt-link :to="treeItem.url">
                    {{ treeItem.label }}
                </nuxt-link>
                <button
                    v-if="treeItem.itemChildren.length > 0"
                    type="button"
                    aria-haspopup="true"
                    :aria-expanded="
                        displayedNode === treeItem.key ? 'true' : 'false'
                    "
                    @click="handleClick(treeItem)"
                >
                    <i class="pi pi-chevron-down" />
                </button>
            </li>
        </ul>

        <div
            ref="floating"
            class="z-10 bg-white text-sm"
            :class="[
                hasTransition
                    ? 'transition-[height,width,transform] duration-200'
                    : ''
            ]"
            :style="floatingStyles"
        >
            <template
                v-for="treeItem in treeItems"
                :key="treeItem.key"
            >
                <div
                    v-if="treeItem.itemChildren.length > 0"
                    v-show="displayedNode === treeItem.key"
                    @mouseover="handleMouseOverMenu(treeItem)"
                    @mouseleave="handleMouseLeaveMenu(treeItem)"
                >
                    <div
                        v-if="treeItem.type === 'StorefrontCatalog'"
                        class="flex border border-gray-300 bg-white p-4 text-gray-700 dark:border-primary-900/40 dark:bg-gray-900 dark:text-white/80"
                    >
                        <ul
                            v-for="subItem in treeItem.itemChildren"
                            :key="subItem.key"
                        >
                            <nuxt-link :to="subItem.url">
                                <li
                                    class="px-5 py-3 font-semibold hover:bg-gray-200"
                                >
                                    {{ subItem.label }}
                                </li>
                            </nuxt-link>

                            <prime-menu
                                :model="subItem.itemChildren"
                                :pt="{
                                    root: {
                                        class: ''
                                    }
                                }"
                                :pt-option="{
                                    mergeProps: true,
                                    mergeSections: true
                                }"
                            />
                        </ul>
                    </div>

                    <prime-menu
                        v-else
                        :model="treeItem.itemChildren"
                        :pt="{
                            root: {
                                class: 'py-1 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-b border-l border-r border-gray-300 dark:border-blue-900/40 w-48'
                            }
                        }"
                        :pt-option="{
                            mergeProps: true,
                            mergeSections: true
                        }"
                    />
                </div>
            </template>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue'
import NuxtLink from '#app/components/nuxt-link'
import type {
    StorefrontCatalogScalar,
    StorefrontNode
} from '~/resources/scalars/storefront-catalog-scalar'
import { useRoute } from '#app'
import PrimeMenu from 'primevue/menu'
import { offset, shift, useFloating } from '@floating-ui/vue'
import { useRouteLink } from '~/composables/routing'

const props = defineProps<{
    catalog: StorefrontCatalogScalar
    storefrontPrefix: string
}>()

type ItemNode = {
    key: string
    label: string
    level: number
    url: string
    type:
        | 'StorefrontPlan'
        | 'StorefrontCalendar'
        | 'StorefrontCalendarGroup'
        | 'StorefrontCatalog'
    itemChildren: ItemNode[]
}

const {
    facilityCreateContractLink,
    storefrontNodeLink,
    storefrontCalendarGroupLink
} = useRouteLink()
// Map a node to a menu item
function mapNodeToMenuItem(
    node: StorefrontNode,
    parent: StorefrontNode,
    level: number
): ItemNode {
    if (node.__typename === 'StorefrontPlan') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontPlan',
            url: facilityCreateContractLink(
                props.storefrontPrefix,
                node.planType,
                node.slug
            ),
            itemChildren: []
        }
    } else if (node.__typename === 'StorefrontCalendar') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontCalendar',
            url: storefrontNodeLink(props.storefrontPrefix, node.slug),
            itemChildren: node.children
                .filter(child => child.showInLinkBar)
                .map(child => mapNodeToMenuItem(child, node, level + 1))
        }
    } else if (node.__typename === 'StorefrontCalendarGroup') {
        return {
            key: node.slug,
            label: node.title,
            level,
            type: 'StorefrontCalendarGroup',
            url: storefrontCalendarGroupLink(
                props.storefrontPrefix,
                parent.slug,
                node.slug
            ),
            itemChildren: node.displayChildren
                ? node.children
                      .filter(child => child.showInLinkBar)
                      .map(child => mapNodeToMenuItem(child, node, level + 1))
                : []
        }
    }

    return {
        key: node.slug,
        label: node.title,
        level,
        type: 'StorefrontCatalog',
        url: storefrontNodeLink(props.storefrontPrefix, node.slug),
        itemChildren: node.children
            .filter(child => child.showInLinkBar)
            .map(child => mapNodeToMenuItem(child, node, level + 1))
    }
}

const treeItems = computed<ItemNode[]>(() => {
    const root = props.catalog
    if (root === null) {
        return []
    }

    return root.children
        .filter(child => child.showInLinkBar)
        .map(child => mapNodeToMenuItem(child, root, 0))
})

const expandedKeys = ref({})
const route = useRoute()
const selectedGuid = computed(() => {
    const slugParam = route.params.slug
    if (typeof slugParam === 'string') {
        return slugParam
    } else {
        return undefined
    }
})

function findSlug(node: StorefrontNode, slug: string): string[] | null {
    if (node.slug === slug) {
        return [node.slug]
    } else if (node.__typename !== 'StorefrontPlan') {
        for (const child of node.children) {
            const found = findSlug(child, slug)
            if (found !== null) {
                return [...found, node.slug]
            }
        }
    }

    return null
}

watchEffect(() => {
    if (selectedGuid.value === undefined) {
        expandedKeys.value = {}
        return
    }

    const c = props.catalog
    if (c === null) {
        return
    }

    const keys = findSlug(c, selectedGuid.value)
    if (keys !== null) {
        const expanded: Record<string, true> = {}
        keys.forEach(key => {
            expanded[key] = true
        })
        expandedKeys.value = expanded
    }
})

const itemRefs = new Map<string, { node: ItemNode; element: HTMLDivElement }>()
function handleItemRef(node: ItemNode, element: HTMLDivElement | null) {
    if (element === null) {
        itemRefs.delete(node.key)
    } else {
        itemRefs.set(node.key, { node, element })
    }
}

const mousedOverElements = ref(new Set<string>())
function handleMouseOver(node: ItemNode) {
    mousedOverElements.value.add(node.key)
}

function handleMouseLeave(node: ItemNode) {
    mousedOverElements.value.delete(node.key)
}

const mousedOverMenus = ref(new Set<string>())
function handleMouseOverMenu(node: ItemNode) {
    mousedOverMenus.value.add(node.key)
}

function handleMouseLeaveMenu(node: ItemNode) {
    mousedOverMenus.value.delete(node.key)
}

function handleClick(node: ItemNode) {
    // if (displayedNode.value?.node.key === node.key) {
    //     displayedNode.value = undefined
    // } else {
    //     displayedNode.value = { node }
    // }
}

const displayedNode = computed(() => {
    if (mousedOverElements.value.size > 0) {
        return mousedOverElements.value.values().next().value
    } else if (mousedOverMenus.value.size > 0) {
        return mousedOverMenus.value.values().next().value
    }

    return undefined
})

const displayedRef = computed(() => {
    if (displayedNode.value === undefined) {
        return undefined
    }

    const item = itemRefs.get(displayedNode.value)
    if (item === undefined) {
        return undefined
    }

    if (item.node.itemChildren.length === 0) {
        return undefined
    }

    return item
})

const hasTransition = ref(false)
watch(displayedRef, () => {
    setTimeout(() => {
        if (displayedRef.value !== undefined) {
            hasTransition.value = true
        } else {
            hasTransition.value = false
        }
    }, 30)
})

const reference = computed(() => displayedRef.value?.element ?? null)
const floating = ref(null)
const { floatingStyles } = useFloating(reference, floating, {
    middleware: [offset(-1), shift()]
})

const menuOverlayStyles = ref<Record<string, string | number>>()
watchEffect(() => {
    if (displayedRef.value !== undefined) {
        const rect = displayedRef.value.element.getBoundingClientRect()
        const node = displayedRef.value.node
        if (node.type === 'StorefrontCatalog') {
            menuOverlayStyles.value = {
                top: rect.bottom - 7 + 'px',
                transform: 'translateX(' + rect.left + 'px)'
            }
        } else {
            menuOverlayStyles.value = {
                top: rect.bottom - 7 + 'px',
                transform: 'translateX(' + rect.left + 'px)'
            }
        }
    }
})
</script>

<style>
.UnderlinedNav > a {
    position: relative;
}

.UnderlinedNav > a::after {
    position: absolute;
    bottom: -2px;
    left: 50%;
    height: 2px;
    width: 0%;
    background-color: rgb(var(--color-primary-600));
    transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
    content: '';
}

.UnderlinedNav:hover > a::after {
    left: 0%;
    width: 100%;
}
</style>

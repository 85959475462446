<template>
    <div class="flex flex-1 flex-col">
        <kiosk-layout
            v-if="isKiosk"
            :storefront-prefix="storefrontPrefix"
            :catalog="catalog"
            :profile-allowed="storefront.profileAllowed"
            :idle-timeout="storefront.idleTimeout"
            :idle-timeout-message="storefront.idleTimeoutMessage"
        >
            <slot>
                <prime-skeleton v-if="isLoading" />
                <slot v-else />
            </slot>
        </kiosk-layout>

        <storefront-layout
            v-else
            :storefront-prefix="storefrontPrefix"
            :catalog="catalog"
        >
            <slot>
                <prime-skeleton v-if="isLoading" />
                <slot v-else />
            </slot>
        </storefront-layout>

        <prime-dialog
            v-model:visible="showWelcome"
            :header="welcomeTitle"
            modal
            :dismissable-mask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        >
            <p
                class="m-0"
                v-html="welcomeText"
            />
        </prime-dialog>
    </div>
</template>

<script setup lang="ts">
import { useSetViewer } from '~/composables/use-login'
import {
    type ActiveFacility,
    ActiveFacilityFragment,
    provideActiveFacility
} from '~/composables/use-active-facility'
import { computed, onBeforeUnmount, provide, ref, watch } from 'vue'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { getFragmentData, graphql } from '~/resources/graphql'
import { createError, useRoute, useState } from '#app'
import StorefrontLayout from '~/components/Layouts/StorefrontLayout/StorefrontLayout.vue'
import KioskLayout from '~/components/Layouts/StorefrontLayout/KioskLayout.vue'
import PrimeSkeleton from 'primevue/skeleton'
import PrimeDialog from 'primevue/dialog'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { createStorefront } from '~/composables/use-storefront'
import { useHead } from 'unhead'

const { isKiosk } = useLayoutMode()
const facilitySlug = useState<string>('facilityParamSlug')
const storefrontBaseSlug = useState('storefrontParamSlug')
const storefrontSlug = computed(() => {
    return typeof storefrontBaseSlug.value === 'string' &&
        storefrontBaseSlug.value.startsWith('kiosk-')
        ? storefrontBaseSlug.value.substring(6)
        : storefrontBaseSlug.value
})

const setViewer = useSetViewer()
const query = useRpQuery({ orgLevel: true })
const { data, isLoading, suspense } = useQuery({
    queryKey: ['DefaultLayoutQuery', storefrontSlug, facilitySlug],
    placeholderData: previousData => previousData,
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query DefaultLayoutQuery(
                    $storefrontSlug: String!
                    $facilitySlug: String!
                ) {
                    viewer {
                        ...ViewerFragment
                    }
                    facility(slug: $facilitySlug) {
                        ...ActiveFacilityFragment
                        storefront(slug: $storefrontSlug) {
                            ...UseStorefrontStorefrontFragment
                        }
                    }
                    organization {
                        welcomeText
                        welcomeTitle
                    }
                }
            `),
            {
                storefrontSlug: storefrontSlug.value,
                facilitySlug: facilitySlug.value
            }
        )
})

const response = await suspense()
if (data.value !== undefined) {
    if (data.value.viewer !== null) {
        setViewer(data.value.viewer)
    } else {
        setViewer(undefined)
    }
}

const activeFacility = computed<ActiveFacility>(() => {
    const result = data.value?.facility
    if (result === undefined || result === null) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return getFragmentData(ActiveFacilityFragment, result)
})
provideActiveFacility(activeFacility)

const storefrontPrefix = computed(() => {
    if (storefrontSlug.value !== '') {
        return `/${activeFacility.value.slug}/${storefrontSlug.value}`
    } else {
        return `/${activeFacility.value.slug}`
    }
})

const storefrontFragment = computed(() => {
    if (
        data.value === undefined ||
        data.value.facility === null ||
        data.value.facility.storefront === null
    ) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return data.value.facility.storefront
})

const storefront = createStorefront(storefrontFragment)

const catalog = computed(() => {
    return storefront.value.config
})
provide('CATALOG', catalog)
provide('STOREFRONT_PREFIX', storefrontPrefix)

const route = useRoute()
const showWelcome = ref(route.query.action === 'welcome')

const welcomeTitle = computed(() => data.value?.organization.welcomeTitle ?? '')
const welcomeText = computed(() => data.value?.organization.welcomeText ?? '')

const unmount = ref(false)
const headStyle = computed(() => {
    if (unmount.value) {
        return []
    }

    return activeFacility.value.branding.styleString
})

useHead({
    style: headStyle
})

onBeforeUnmount(() => {
    // Clear styles when leaving the component
    unmount.value = true
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

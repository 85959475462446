<template>
    <sidebar
        :visible="cartVisible"
        position="right"
        :block-scroll="true"
        :pt="{
            root: () => ({
                class: 'w-[40rem]'
            })
        }"
        :pt-options="{ mergeProps: true }"
        @update:visible="setCartVisible"
    >
        <template #header>
            <div
                class="flex flex-1 text-lg font-semibold uppercase tracking-wider md:hidden"
            >
                ({{ cart?.items.length ?? 0 }}) Cart &nbsp;<span
                    class="text-gray-500"
                    >&mdash;</span
                >&nbsp;
                {{ activeFacility.shortName }}
            </div>
        </template>

        <cart-contents
            @checkout="setCartVisible(false)"
            @close="setCartVisible(false)"
        />
    </sidebar>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const CartSidebarViewerCartFragment = graphql(/* GraphQL */ `
    fragment CartSidebarViewerCartFragment on Cart {
        items {
            __typename
            ... on CartContractItem {
                key
                ...CartSidebarContractCartItemFragment
                contract {
                    ... on PlanContract {
                        readyForPurchase(input: { checkDocument: true })
                    }
                }
            }
            ... on CartProductItem {
                key
            }
        }
        inclusiveTaxTotal
        exclusiveTaxTotal
        subtotal
        salesTaxItems {
            key
            description
            total
        }
        total
    }
`)
</script>

<script setup lang="ts">
import Sidebar from 'primevue/sidebar'
import type { ComputedRef } from 'vue'
import { computed, nextTick, watch } from 'vue'
import {
    setCartVisible,
    useCartData,
    useCartVisible
} from '~/composables/use-cart'
import { getFragmentData } from '~/resources/graphql'
import type { CartSidebarViewerCartFragmentFragment } from '~/resources/graphql/graphql'
import { useActiveFacility } from '~/composables/use-active-facility'
import CartContents from '~/components/CartSidebar/CartContents.vue'

const cartVisible = useCartVisible()
watch(cartVisible, (newValue, oldValue) => {
    if (newValue && !oldValue) {
        nextTick(() =>
            document.getElementById('cartSidebarCheckoutButton')?.focus()
        )
    }
})

const cartData = useCartData()
const cart: ComputedRef<CartSidebarViewerCartFragmentFragment | undefined> =
    computed(() => {
        const data = cartData.value
        // Data still loading
        if (data === undefined) {
            return undefined
        }

        return getFragmentData(CartSidebarViewerCartFragment, data)
    })

const activeFacility = useActiveFacility()
</script>

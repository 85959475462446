<template>
    <template v-if="activeFacilities.length > 1">
        <prime-button
            type="button"
            text
            severity="secondary"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            icon="pi pi-angle-down"
            icon-pos="right"
            label="Our Locations"
            :pt="{
                label: '!font-medium'
            }"
            :pt-options="{ mergeProps: true }"
            @click="toggle"
        />

        <prime-menu
            id="overlay_menu"
            ref="menu"
            :model="activeFacilities"
            :popup="true"
        >
            <template #item="{ item }">
                <nuxt-link
                    :to="item.route"
                    class="flex w-full items-center p-3"
                >
                    {{ item.label }}
                </nuxt-link>
            </template>
        </prime-menu>
    </template>
</template>

<script setup lang="ts">
import NuxtLink from '#app/components/nuxt-link'
import PrimeMenu from 'primevue/menu'
import PrimeButton from 'primevue/button'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { computed, ref } from 'vue'
import { useRouteLink } from '~/composables/routing'

const query = useRpQuery({ orgLevel: true })
// TODO: when there are more than 30 facilities
// TODO: Limit to active facilities on server
const { data, suspense } = useQuery({
    queryKey: ['OurLocationsQuery'],
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query OurLocationsQuery {
                    facilities(first: 30) {
                        edges {
                            node {
                                id
                                shortName
                                longName
                                slug
                                active
                                storefront {
                                    id
                                }
                            }
                        }
                    }
                }
            `)
        )
})

const { facilityHomeLink } = useRouteLink()
const activeFacilities = computed(() => {
    return (
        data.value?.facilities.edges
            .filter(edge => {
                return (
                    edge.node.active === true && edge.node.storefront !== null
                )
            })
            .map(edge => ({
                label: edge.node.shortName,
                route: facilityHomeLink(edge.node.slug.toLowerCase())
            })) ?? []
    )
})

const menu = ref()
function toggle(event) {
    menu.value.toggle(event)
}

await suspense()
</script>

<template>
    <app-base>
        <header class="border-b">
            <nuxt-link
                v-if="!isLightbox"
                class="text-button flex h-10 items-center justify-center bg-primary-500 py-2 text-lg font-medium uppercase hover:underline"
                :to="activeFacility.organization.topBarLink"
            >
                {{ activeFacility.organization.topBarText }}
            </nuxt-link>

            <div
                class="relative flex flex-col justify-center overflow-hidden bg-white"
            >
                <div>
                    <div
                        class="relative mx-0 flex items-center justify-between gap-4 px-4 py-6 md:px-7 lg:static lg:px-16 lg:py-6"
                    >
                        <div class="flex items-center gap-2 lg:gap-4">
                            <button
                                class="block p-4 hover:bg-gray-100 lg:hidden"
                                type="button"
                                @click="handleHamburgerClick"
                            >
                                <i
                                    :class="
                                        mobileSidebarVisible
                                            ? 'pi pi-times'
                                            : 'pi pi-bars'
                                    "
                                />
                            </button>

                            <organization-facility-logo />

                            <div class="hidden sm:block">
                                <nuxt-link
                                    :to="facilityHomeLink(activeFacility.slug)"
                                >
                                    <prime-button
                                        text
                                        severity="secondary"
                                        icon="pi pi-home"
                                        :label="activeFacility.shortName"
                                    />
                                </nuxt-link>
                            </div>

                            <div class="-ml-4 hidden lg:block">
                                <our-locations />
                            </div>
                        </div>

                        <div
                            class="flex w-full flex-1 items-center justify-end"
                        >
                            <ul class="flex items-center">
                                <contact-us-button
                                    v-if="
                                        activeFacility.organization
                                            .contactUsLinkText !== null
                                    "
                                    :link-text="
                                        activeFacility.organization
                                            .contactUsLinkText
                                    "
                                    :facility-id="activeFacility.id"
                                />

                                <cart-header-link />

                                <profile-buttons />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <storefront-desktop-menu
                :catalog="catalog"
                :storefront-prefix="storefrontPrefix"
                class="hidden lg:block"
            />

            <cart-sidebar />
        </header>

        <div class="flex flex-1 flex-col">
            <div
                class="z-2 mx-0 my-2 flex-1 px-4 py-4 md:mx-6 md:my-6 lg:mx-8 lg:px-8"
            >
                <div class="mx-auto flex max-w-screen-xl items-start gap-8">
                    <div class="w-full">
                        <slot />
                    </div>
                </div>
            </div>

            <powered-by-footer />
        </div>

        <Transition name="fade">
            <div
                v-if="mobileSidebarVisible"
                class="fixed bottom-0 left-0 right-0 z-10 bg-white"
                :class="isLightbox ? 'top-[92px]' : 'top-[127px]'"
            >
                <storefront-mobile-menu
                    :catalog="catalog"
                    :storefront-prefix="storefrontPrefix"
                    @close="mobileSidebarVisible = false"
                />
            </div>
        </Transition>
    </app-base>
</template>

<script setup lang="ts">
import NuxtLink from '#app/components/nuxt-link'
import StorefrontDesktopMenu from '~/components/Storefront/StorefrontDesktopMenu.vue'
import CartSidebar from '~/components/CartSidebar/CartSidebar.vue'
import AppBase from '~/components/Layouts/AppBase.vue'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import OrganizationFacilityLogo from '~/components/Layouts/OrganizationFacilityLogo.vue'
import CartHeaderLink from '~/components/Layouts/FacilityLayout/CartHeaderLink.vue'
import ProfileButtons from '~/components/Layouts/ProfileButtons.vue'
import ContactUsButton from '~/components/Layouts/ContactUsButton.vue'
import OurLocations from '~/components/Layouts/OurLocations.vue'
import StorefrontMobileMenu from '~/components/Storefront/StorefrontMobileMenu.vue'
import PrimeButton from 'primevue/button'
import { ref, watch, watchEffect } from 'vue'
import { useRoute } from '#app'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type { StorefrontCatalogScalar } from '~/resources/scalars/storefront-catalog-scalar'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useRouteLink } from '~/composables/routing'

defineProps<{
    catalog: StorefrontCatalogScalar
    storefrontPrefix: string
}>()

const { isLightbox } = useLayoutMode()

const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()
const mobileSidebarVisible = ref(false)
function handleHamburgerClick() {
    mobileSidebarVisible.value = !mobileSidebarVisible.value
    if (mobileSidebarVisible.value === true) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        document.body.classList.add('overflow-hidden')
    }
}

// Close mobile menu on route navigate
const route = useRoute()
watch(
    () => route.fullPath,
    () => {
        mobileSidebarVisible.value = false
    }
)

// Close mobile menu when lg or larger
const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndLarger = breakpoints.greaterOrEqual('lg') // lg and larger
watchEffect(() => {
    if (lgAndLarger.value) {
        mobileSidebarVisible.value = false
    }
})

// Remove scroll breaking whenever mobile when is closed
watchEffect(() => {
    if (!mobileSidebarVisible.value) {
        if (document) {
            document.body.classList.remove('overflow-hidden')
        }
    }
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

<template>
    <div
        class="mx-6 mb-12 max-w-screen-xl space-y-5 lg:mx-auto lg:my-12 lg:min-w-[1024px]"
    >
        <div>
            <span class="text-xl text-gray-700">Thanks!</span>
            <div class="my-2 text-4xl font-bold text-gray-900">
                Successful Order
            </div>
            <p
                v-if="!isKiosk"
                class="mb-4 mt-0 p-0 text-xl text-gray-700"
            >
                We look forward to seeing you soon!
            </p>
            <div
                :style="{
                    height: '3px',
                    background:
                        'linear-gradient(90deg, rgb(var(--color-primary-600)) 0%, rgba(33, 150, 243, 0) 50%)'
                }"
            ></div>
        </div>

        <div
            class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="mb-3 sm:mb-0">
                <span class="mr-2 text-xl font-medium text-gray-900"
                    >Reference number:</span
                >
                <span class="text-xl font-medium text-primary-500"
                    >#{{ refNumber }}</span
                >
            </div>
        </div>

        <div
            v-if="!isKiosk"
            class="flex justify-end"
        >
            <nuxt-link :to="facilityHome">
                <prime-button
                    label="Return to Homepage"
                    icon="pi pi-fw pi-arrow-left"
                    severity="secondary"
                />
            </nuxt-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useHead } from 'unhead'
import PrimeButton from 'primevue/button'
import nuxtLink from '#app/components/nuxt-link'
import { computed } from 'vue'
import { useRouteLink } from '~/composables/routing'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useLayoutMode } from '~/composables/use-layout-mode'

defineProps<{
    refNumber: number
}>()

useHead({
    title: 'Success'
})

const { isKiosk } = useLayoutMode()
const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()
const facilityHome = computed(() => facilityHomeLink(activeFacility.value.slug))
</script>

<template>
    <div class="mb-12 space-y-12">
        <prime-message
            :closable="false"
            severity="success"
            :pt="{
                text: {
                    class: 'w-full'
                }
            }"
            :pt-options="{
                mergeProps: true
            }"
        >
            <div class="flex w-full items-center justify-between gap-16">
                <div>{{ resetMessage }}</div>

                <prime-button
                    label="Reset Kiosk Now"
                    severity="secondary"
                    @click="$emit('reset')"
                />
            </div>
        </prime-message>

        <div
            v-if="false"
            class="flex items-center justify-between gap-4"
        >
            <prime-button
                :label="continueAsLabel"
                outlined
                @click="$emit('continue')"
            />

            <div class="flex-1">
                If you need to sign another agreement or make another purchase
                you can continue signed into your profile.
            </div>
        </div>
    </div>

    <checkout-success
        v-if="checkoutResultData.includedInvoice"
        :checkout-result-data="checkoutResultData.data"
    />

    <contract-success
        v-else
        :ref-number="checkoutResultData.data.referenceNumber"
    />
</template>

<script setup lang="ts">
import { useHead } from 'unhead'
import CheckoutSuccess from '~/components/Checkout/CheckoutSuccess.vue'
import PrimeMessage from 'primevue/message'
import PrimeButton from 'primevue/button'
import { computed, ref } from 'vue'
import { useIntervalFn } from '@vueuse/core'
import type { KioskSuccessData } from '~/composables/use-kiosk-success'
import ContractSuccess from '~/components/Checkout/ContractSuccess.vue'

const props = defineProps<{
    checkoutResultData: KioskSuccessData
}>()

const emit = defineEmits<{
    (e: 'reset'): void
    (e: 'continue'): void
}>()

useHead({
    title: 'Success'
})

const resetCounter = ref<number>(10)
const resetMessage = computed(() => {
    return `This kiosk will reset in ${resetCounter.value} seconds.`
})

useIntervalFn(() => {
    if (resetCounter.value !== null) {
        resetCounter.value -= 1
        if (resetCounter.value < 1) {
            resetCounter.value = null
            emit('reset')
        }
    }
}, 1000)

// TODO: Put this in a fragment? Or inside checkout success
const continueAsLabel = computed(() => {
    if (props.checkoutResultData.includedInvoice) {
        return 'Continue As ' + props.checkoutResultData.data.customer.firstName
    } else {
        return 'Continue As ' + props.checkoutResultData.data.firstName
    }
})
</script>
